import React, { useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonLogin from 'src/components/ButtonLogin';
import {
  IconeInstagram,
  IconeLinkedin,
  IconeRedefinir,
} from 'src/components/Icons';
import InputLogin from 'src/components/InputLogin';
import api from 'src/services/api';
import keys from 'src/services/keys';
import { Creators as AuthActions } from 'src/store/ducks/auth';

import BalaoComoFunciona from '../../../assets/img/balao-como-funciona.png';
import ImagemComoFunciona from '../../../assets/img/imagem-como-funciona.png';
import ImagemLogin from '../../../assets/img/imagem-ligin-cempre.png';
import GreenPlatPowered from '../../../assets/svg/gp-powered.png';
import LogoCempreWhite from '../../../assets/svg/logo-cempre-rodape-white.png';
import LogoCempre from '../../../assets/svg/logo-cempre.png';
import LogoViWhite from '../../../assets/svg/logo-vi-white-rodape.png';
import LogoVi from '../../../assets/svg/logo-vi.png';
import {
  BlockInput,
  Container,
  Content,
  Footer,
  FormContainer,
  Header,
  ReCaptcha,
} from './styles';

const RecriarSenhaCempre: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Email
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  // Recaptcha
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [showRecaptcha] = useState<boolean>(
    keys.REACT_APP_RECAPTCHA_ENABLED === 'true'
  );
  const [recaptcha_privatekey, setRecaptcha_privatekey] = useState<any>(
    '5948086e-e295-43a2-8b3d-189b7723db99'
  );

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  function voltarLogin() {
    history.push('/cempre');
  }

  // Handle Erro Email
  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  // Handle Change Email
  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
  };

  const IsEmail = (email: string) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const forgotRequest = async () => {
    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (!IsEmail(email)) {
        toast.error('E-mail digitado não é valido!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorEmail(true);
      } else {
        if (showRecaptcha && recaptchavalue == '') {
          toast.error('Validação do rCaptcha é obrigatória!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const loading = true;
          dispatch(AuthActions.authLoading(loading));
          const data = {
            email: email,
          };
          try {
            await api.user.post('/api/v1/users/password/remember', data);

            history.push('/esqueci-minha-senha/info', data);
          } catch (error) {
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
            if (error?.response?.status === 404) {
              toast.error('E-mail não cadastrado!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              funcErrorEmail(true);
            }
          }
        }
      }
    }
  };

  return (
    <Container>
      <Header>
        <img className="cempre" src={LogoCempre} alt="Logo Cempre" />
        <img className="vi" src={LogoVi} alt="Logo Vi" />
      </Header>
      <Content>
        <div className="img-container">
          <img className="img-fundo" src={ImagemLogin} alt="" />
          <FormContainer>
            <div className="login">
              <IconeRedefinir />
              <p>Redefinir Senha</p>
            </div>
            <div className="login-mensagem">
              <strong>Precisamos verificar sua identidade!</strong>
              <p>
                Informe o <strong>e-mail</strong> utilizado na plataforma para
                enviarmos o <strong>link de redefinição de senha.</strong>
              </p>
            </div>
            <div className="input">
              <p>Insira seu e-mail</p>
              <BlockInput>
                <InputLogin
                  placeholder="nome@email.com"
                  onChange={(e) => changeEmail(e.target.value)}
                />
              </BlockInput>
            </div>
            {showRecaptcha && (
              <ReCaptcha>
                <HCaptcha
                  sitekey={recaptcha_privatekey}
                  onVerify={(token) => changeRecaptha(token)}
                />
              </ReCaptcha>
            )}
            <ButtonLogin
              color="#35A2D0"
              type="button"
              onClick={() => forgotRequest()}
            >
              <p>Enviar Link</p>
            </ButtonLogin>
            <div onClick={() => voltarLogin()} className="esqueci-senha">
              ACESSAR UTILIZANDO LOGIN / SENHA
            </div>
            <div className="logo">
              <img src={GreenPlatPowered} alt="" />
            </div>
          </FormContainer>
        </div>
      </Content>
      <Footer>
        <div className="footer-top">
          <div className="footer-top_conecte">
            <p className="conecte">conecte-se:</p>
            <IconeInstagram />
            <p className="instagram">instagram</p>
            <IconeLinkedin />
            <p className="linkedin">linked-in</p>
          </div>
          <div className="footer-top_logos">
            <img className="cempre" src={LogoCempreWhite} alt="Logo Cempre" />
            <img className="vi" src={LogoViWhite} alt="Logo Vi" />
          </div>
        </div>
        <div className="footer-bottom">
          <img src={GreenPlatPowered} alt="" />
          <p>® 2024 GreenPlat™ | Todos os direitos reservados.</p>
        </div>
      </Footer>
    </Container>
  );
};

export default RecriarSenhaCempre;
