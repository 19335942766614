import React, { useEffect, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import view from 'src/assets/img/view.png';
import visibility from 'src/assets/img/visibility.png';
import ButtonLogin from 'src/components/ButtonLogin';
import {
  ArrowRight,
  IconeInstagram,
  IconeLinkedin,
  IconeLogin,
} from 'src/components/Icons';
import InputLogin from 'src/components/InputLogin';
import api from 'src/services/api';
import keys from 'src/services/keys';
import terceiro from 'src/services/terceiro';
import { Creators as AuthActions } from 'src/store/ducks/auth';

import BalaoComoFunciona from '../../../assets/img/balao-como-funciona.png';
import ImagemComoFunciona from '../../../assets/img/imagem-como-funciona.png';
import ImagemLogin from '../../../assets/img/imagem-ligin-cempre.png';
import GreenPlatPowered from '../../../assets/svg/gp-powered.png';
import LogoCempreWhite from '../../../assets/svg/logo-cempre-rodape-white.png';
import LogoCempre from '../../../assets/svg/logo-cempre.png';
import LogoViWhite from '../../../assets/svg/logo-vi-white-rodape.png';
import LogoVi from '../../../assets/svg/logo-vi.png';
import {
  BlockInput,
  Container,
  Content,
  Footer,
  FormContainer,
  Header,
  ImageInput,
  ReCaptcha,
} from './styles';

const LoginCempre: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Input Senha
  const [show_input, setShowInput] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(true);

  // Input Email
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  // ReCaptcha
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [showRecaptcha] = useState<boolean>(
    keys.REACT_APP_RECAPTCHA_ENABLED === 'true'
  );
  const [recaptcha_privatekey, setRecaptcha_privatekey] = useState<any>(
    '5948086e-e295-43a2-8b3d-189b7723db99'
  );

  // Handle Change Email
  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  // Handle Change Senha
  const changePassword = (password: string) => {
    setPassword(password);
    funcErrorPassword(false);
    funcErrorEmail(false);
  };

  // Handle Erro Senha
  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  // Handle Erro Email
  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  // Ver / Ocultar senha
  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  // Handle Change Recaptcha
  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  // Nevega para esquecu minha senha
  const forgotMyPassword = () => {
    history.push('/esqueci-minha-senha-cempre');
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  // Checa conexão
  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');

      if (sucesso && sucesso == '1') {
        toast.success(' Senha editada com sucesso! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (erro && erro == '1') {
        toast.error(
          ' Sua sessão foi finalizada após 20 minutos de inatividade! ',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else if (erro && erro == '2') {
        // Vamos atualizar a tela, caso já esteja logado no dispositivo atual, já entrará no sistema!
        toast.error(
          ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              window.location.href = '/dashboard';
            },
          }
        );
      } else if (erro && erro == '3') {
        toast.error(
          'Login ou senha inválidos! Se o problema persistir, redefina sua senha!',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else if (erro && erro == '4') {
        toast.error(' O tempo limite para logar no sistema foi atingido! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      localStorage.removeItem('erro');
      localStorage.removeItem('sucesso');
    };
    getLogin();
  }, []);

  // Handle SignIn
  const signInPass = async () => {
    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (password == '') {
        toast.error('Password não pode ser vazio!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorPassword(true);
      } else {
        if (!IsEmail(email)) {
          toast.error('E-mail digitado não é valido!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (showRecaptcha && recaptchavalue == '') {
            toast.error('Validação do hCaptcha é obrigatória!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            let res: any = {};
            res = await authRequest(email, password, recaptchavalue);
          }
        }
      }
    }
  };

  const IsEmail = (email: string) => {
    return true;
  };

  const authRequest = async (login, pass, hcaptcha) => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: login,
      password: pass,
      'h-captcha-response': hcaptcha,
    };
    try {
      const response = await api.user.post('/api/v1/login', data);

      const { modules, token } = response.data;
      if (modules.length == 1 && modules[0]?.user_profile == null) {
        const data = {
          uuid: modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                if (
                  companies[0]?.plans == 'CANCELADO' ||
                  companies[0]?.plan == 'CANCELADO'
                ) {
                  const loading = false;
                  dispatch(AuthActions.authLoading(loading));
                  toast.error(
                    'Seu contrato de uso com a GreenPlat está vencido. Caso queira renovar acesse um dos nossos canais de atendimento.',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  const response = await api.user.post(
                    '/api/v1/companies/current',
                    data,
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenNew,
                      },
                    }
                  );

                  const dataNew = {
                    token: response.data.token,
                  };
                  if (modules[0].email == 'restricted') {
                    const loading = false;

                    dispatch(AuthActions.authLoading(loading));
                    dispatch(
                      AuthActions.authSuccess(
                        response.data.token,
                        modules[0],
                        companies[0]
                      )
                    );
                  } else {
                    window.location.href =
                      'api/v1/logar_by_micro_service/' + response.data.token;
                  }
                }
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  localStorage.setItem('erro', '2');
                  toast.error(
                    ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }
              }
            } else {
              dispatch(AuthActions.authSelectCompanie(token, { modules }));
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
              history.push('/escolher-empresa');
            }
          }
        } catch (error) {
          if (error.response.data.error == 'No companies bounded to user') {
            toast.error(
              ' Usuário inativo no sistema. Por favor entre em contato com o responsável para reativar seu acesso ao sistema! ',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
          }
        }
      } else {
        if (
          modules[0]?.user_profile?.usuario_id != null &&
          modules.length == 1
        ) {
          window.location.href =
            terceiro.REACT_APP_TERCEIRO +
            '/logar_by_micro_service/' +
            modules[0]?.user_profile?.usuario_id +
            '/' +
            token;
        } else {
          dispatch(AuthActions.authSelectCompanie(token, { modules }));
          const loading = false;
          dispatch(AuthActions.authLoading(loading));
          history.push('/escolher-empresa');
        }
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));
      if (
        error.response.data.message ==
        'The maximum number of login attempts has been reached. We are sending you an email to reset your password.'
      ) {
        toast.error(
          'O número máximo de tentativas de login foi atingido. Estamos lhe enviando um e-mail para redefinir sua senha.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        if (
          error.response.data.message ==
          'Change your password to meet secure password requirements!'
        ) {
          toast.error(
            'Altere sua senha para atender aos requisitos de senha segura!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          history.push(
            '/login/recriar-senha/' +
              error.response.data.data.id +
              '/' +
              error.response.data.data.token +
              '/senha-forte'
          );
        } else {
          toast.error('Login ou senha inválidos!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  return (
    <Container>
      <Header>
        <img className="cempre" src={LogoCempre} alt="Logo Cempre" />
        <img className="vi" src={LogoVi} alt="Logo Vi" />
      </Header>
      <Content>
        <div className="img-container">
          <img className="img-fundo" src={ImagemLogin} alt="" />
          <FormContainer>
            <div className="login">
              <IconeLogin />
              <p>Login</p>
            </div>
            <p>
              Digite os <strong>dados abaixo</strong> para fazer o seu{' '}
              <strong>login</strong>.
            </p>
            <div className="input">
              <p>Insira seu e-mail</p>
              <BlockInput>
                <InputLogin
                  placeholder="nome@email.com"
                  onChange={(e) => changeEmail(e.target.value)}
                />
              </BlockInput>
            </div>
            <div className="input">
              <p>Insira sua senha</p>
              <BlockInput>
                <InputLogin
                  type={show_input ? 'text' : 'password'}
                  placeholder="Senha de acesso"
                  onChange={(e) => changePassword(e.target.value)}
                />
                <ImageInput onClick={() => funcPassView(showPass)}>
                  <img
                    className="img-senha"
                    src={showPass ? visibility : view}
                    style={{
                      position: 'absolute',
                      width: '8%',
                      float: 'left',
                      right: '60px',
                      transform: 'translateY(-15px)',
                    }}
                    alt="Plataforma Verde"
                  />
                </ImageInput>
              </BlockInput>
            </div>
            {showRecaptcha && (
              <ReCaptcha>
                <HCaptcha
                  sitekey={recaptcha_privatekey}
                  onVerify={(token) => changeRecaptha(token)}
                />
              </ReCaptcha>
            )}
            <ButtonLogin
              color="#35A2D0"
              type="button"
              onClick={() => signInPass()}
            >
              <p>Entrar</p>
            </ButtonLogin>
            <div onClick={() => forgotMyPassword()} className="esqueci-senha">
              ESQUECI MINHA SENHA
            </div>
            <div className="logo">
              <img src={GreenPlatPowered} alt="" />
            </div>
          </FormContainer>
        </div>
        <div className="como-funciona-container">
          <div className="horizontal-bar" />
          <div className="left-wrapper">
            <div className="left">
              <img src={BalaoComoFunciona} alt="" />
              <div className="como-funciona_titulo">Como Funciona</div>
              <p className="como-funciona_descricao">
                O sistema de verificação é simples, garantindo a transparência,
                rastreabilidade e não colidência para fins de comprovação de
                créditos de reciclagem.
              </p>
            </div>
          </div>
          <ArrowRight className="arrow" />
          <div className="right">
            <img src={ImagemComoFunciona} alt="" />
          </div>
        </div>
      </Content>
      <Footer>
        <div className="footer-top">
          <div className="footer-top_conecte">
            <p className="conecte">conecte-se:</p>
            <IconeInstagram
              onClick={() =>
                openInNewTab('https://www.instagram.com/cemprebr/ ')
              }
            />
            <a
              className="socialLink"
              target="_blank"
              href="https://www.instagram.com/cemprebr/"
              rel="noreferrer"
            >
              <p className="instagram">Instagram</p>
            </a>
            <IconeLinkedin
              onClick={() =>
                openInNewTab(
                  'https://www.linkedin.com/company/cempre-compromisso-empresarial-para-reciclagem/?originalSubdomain=br'
                )
              }
            />
            <a
              className="socialLink"
              target="_blank"
              href="https://www.linkedin.com/company/cempre-compromisso-empresarial-para-reciclagem/?originalSubdomain=br"
              rel="noreferrer"
            >
              <p className="linkedin">LinkedIn</p>
            </a>
          </div>
          <div className="footer-top_logos">
            <img className="cempre" src={LogoCempreWhite} alt="Logo Cempre" />
            <img className="vi" src={LogoViWhite} alt="Logo Vi" />
          </div>
        </div>
        <div className="footer-bottom">
          <img src={GreenPlatPowered} alt="" />
          <p>® 2024 GreenPlat™ | Todos os direitos reservados.</p>
        </div>
      </Footer>
    </Container>
  );
};

export default LoginCempre;
