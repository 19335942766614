import { FC } from 'react';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export interface SubRouteInterface extends RouteInterface {
  show_in_sub_menu?: boolean;
}

export interface RouteInterface {
  exact?: boolean;
  component: FC;
  label: string;
  path: string;
  title?: string;
  type: 'auth' | 'private' | 'public';
  breadcrumbs?: string[];
  sub_routes?: SubRouteInterface[];
}

export const authRoutes: RouteInterface[] = [
  {
    component: AuthPages.ForgotMyPassword,
    exact: true,
    label: 'Esqueci minha senha',
    path: '/esqueci-minha-senha',
    type: 'auth',
  },
  {
    component: AuthPages.ChooseCompany,
    exact: true,
    label: 'Escolher Empresa',
    path: '/escolher-empresa',
    type: 'auth',
  },
  {
    component: AuthPages.ChooseModule,
    exact: true,
    label: 'Escolher Modulo',
    path: '/escolher-modulo',
    type: 'auth',
  },
  {
    component: AuthPages.ForgotMyPasswordInfo,
    label: 'Esqueci minha senha Informação',
    path: '/esqueci-minha-senha/info',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.LoginCempre,
    label: 'Login Cempre',
    path: '/cempre',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.RedefinirSenhaCempre,
    label: 'Redefinir Senha Cempre',
    path: '/esqueci-minha-senha-cempre',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/login',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.LoginCarrefour,
    label: 'Login Carrefour',
    path: '/carrefour',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.LoginArcosDourados,
    label: 'Login Arcos Dourados',
    path: '/arcosdourados',
    type: 'auth',
  },
  {
    component: AuthPages.ChooseCompanyCarrefour,
    exact: true,
    label: 'Escolher Empresa Carrefour',
    path: '/escolher-empresa-carrefour',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ChooseModuleCarrefour,
    label: 'Escolher Modulo Carrefour',
    path: '/escolher-modulo-carrefour',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ChooseModuleArcosDourados,
    label: 'Escolher Modulo Arcos Dourados',
    path: '/escolher-modulo-arcosdourados',
    type: 'auth',
  },
  {
    component: AuthPages.ChooseCompanyArcosDourados,
    exact: true,
    label: 'Escolher Empresa Arcos Dourados',
    path: '/escolher-empresa-arcosdourados',
    type: 'auth',
  },

  {
    exact: true,
    component: AuthPages.LoginSSO,
    label: 'Login',
    path: '/login-sso',
    type: 'auth',
  },
  {
    component: AuthPages.RecoverPassword,
    label: 'Recuperar senha',
    path: '/recuperar-senha',
    type: 'auth',
  },
  {
    component: AuthPages.ErroInatividade,
    label: 'Erro Inatividade',
    path: '/erro/inatividade',
    type: 'auth',
  },
  {
    component: AuthPages.ErroJaLogado,
    label: 'Erro Inatividade',
    path: '/erro/jalogado',
    type: 'auth',
  },
  {
    component: AuthPages.ErroCredenciais,
    label: 'Erro Credenciais',
    path: '/erro/credenciais',
    type: 'auth',
  },
  {
    component: AuthPages.SucessoTrocaSenha,
    label: 'Troca de senha',
    path: '/sucesso/troca_senha',
    type: 'auth',
  },
  {
    component: AuthPages.RecriarSenha,
    label: 'Troca de senha',
    path: '/login/recriar-senha/:id/:tokenPass',
    type: 'auth',
  },
  {
    component: AuthPages.LoginGreen,
    label: 'Login Greeneletron',
    path: '/login/greeneletron/:tokenPass',
    type: 'auth',
  },
  {
    component: AuthPages.RecriarSenhaForte,
    label: 'Troca de senha',
    path: '/login/recriar-senha/:id/:tokenPass/senha-forte',
    type: 'auth',
  },
  {
    component: AuthPages.PrimeiroAcesso,
    label: 'Troca de senha primeiro acesso',
    path: '/login/minha-senha/:id/:tokenPass',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.LoginCempre,
    label: 'Login Cempre',
    path: '/cempre',
    type: 'auth',
  },
];

export const privateRoutes: RouteInterface[] = [
  {
    component: PrivatePages.Configs,
    label: 'Configurações',
    path: '/configuracoes',
    type: 'private',
    sub_routes: [],
    breadcrumbs: ['Configurações'],
  },
  {
    component: PrivatePages.Dashboard,
    label: 'Painel de Controle',
    path: '/dashboard',
    type: 'private',
    breadcrumbs: ['Painel de Controle'],
  },
  // Providers
  {
    exact: true,
    component: PrivatePages.Providers.List,
    label: 'Prestadores',
    path: '/prestadores',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Prestadores'],
  },
  {
    component: PrivatePages.Providers.Edit,
    label: 'Cadastro de prestadores',
    path: '/prestadores/edicao/:id',
    type: 'private',
    breadcrumbs: [
      'Modulo Gerador',
      'Cadastros',
      'Prestadores',
      'Editar Prestador',
    ],
  },
  {
    component: PrivatePages.Providers.Store,
    label: 'Cadastro de prestadores',
    path: '/prestadores/cadastro',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Cadastros', 'Cadastrar Novo Prestador'],
  },
  // Emissions
  {
    exact: true,
    component: PrivatePages.Emissions.List,
    label: 'Saídas',
    path: '/saidas',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Movimentações', 'Saídas'],
  },
  {
    component: PrivatePages.Emissions.Store,
    label: 'Criar documento de saída',
    path: '/saidas/cadastro',
    type: 'private',
    breadcrumbs: [
      'Modulo Gerador',
      'Movimentações',
      'Saída',
      'Criar Documento de Saída',
    ],
  },
  // Residues
  {
    exact: true,
    component: PrivatePages.Residues.List,
    label: 'Resíduos',
    path: '/residuos',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Resíduos'],
  },
  {
    component: PrivatePages.Residues.Store,
    label: 'Cadastro de prestadores',
    path: '/residuos/cadastro',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Cadastros', 'Cadastrar Novo Resíduo'],
  },
  // Legislation
  {
    exact: true,
    component: PrivatePages.Legislation.List,
    label: 'Legislação',
    path: '/legislacao',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Legislação'],
  },
  // Perfil da Empresa
  {
    exact: true,
    component: PrivatePages.CompanyProfile.List,
    label: 'Perfil da Empresa',
    path: '/perfil-empresa',
    type: 'private',
    breadcrumbs: ['Modulo Gerador', 'Painel de Controle'],
  },
  {
    exact: true,
    component: PrivatePages.Logout.Logout,
    label: 'Logout',
    path: '/sair',
    breadcrumbs: ['Modulo Gerador', 'logout'],
    type: 'private',
  },

  // Contratante
  {
    exact: true,
    component: PrivatePages.Contractor.List,
    label: 'Contratantes',
    path: '/contratantes',
    type: 'private',
    breadcrumbs: ['Modulo Administrativo', 'Cadastros', 'Contratantes'],
  },
  {
    component: PrivatePages.Contractor.Edit,
    label: 'Cadastro de contratantes',
    path: '/contratantes/edicao/:id',
    type: 'private',
    breadcrumbs: [
      'Modulo Administrativo',
      'Cadastros',
      'Contratantes',
      'Editar Contratantes',
    ],
  },
  {
    component: PrivatePages.Contractor.Store,
    label: 'Cadastro de Contratantes',
    path: '/contratantes/cadastro',
    type: 'private',
    breadcrumbs: [
      'Modulo Administrativo',
      'Cadastros',
      'Cadastrar Novo Contratantes',
    ],
  },
  // Farol de Integrações
  {
    component: PrivatePages.Lighthouse,
    label: 'Farol de Integrações',
    path: '/integracoes',
    type: 'private',
    breadcrumbs: ['Farol de Integrações'],
  },
];

export const publicRoutes: RouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [...authRoutes, ...privateRoutes, ...publicRoutes];
