import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import arte from 'src/assets/img/arte-login.png';
import company from 'src/assets/img/ico-building.png';
import logoSSMA from 'src/assets/img/logo-arcos-ssma.png';
import logo from 'src/assets/img/logo-arcos.png';
import BockInput from 'src/components/BockInput';
import BockInputArcos from 'src/components/BockInputArcos';
import ButtonArcosContinuar from 'src/components/ButtonArcosContinuar';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import ButtonLogin from 'src/components/ButtonLogin';
import ContactGreenPlateArcos from 'src/components/ContactGreenPlateArcos';
import ContentTextPage from 'src/components/ContentTextPage';
import InputArcosLogin from 'src/components/InputArcosLogin';
import SelectLogin from 'src/components/SelectLogin';
import SelectLoginArcos from 'src/components/SelectLoginArcos';
import api from 'src/services/api';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { RootState } from 'src/store/ducks/index';
import getApiErrors from 'src/utils/getApiErrors';

import {
  Container,
  Logo,
  FormLogin,
  LeftBox,
  InputArcos,
  RightBox,
  TextEntre,
  TextFooter,
  MenuItemLink,
  ReCaptcha,
  FooterArcos,
  SSMA,
} from './styles';

const ChooseModule: React.FC = (props) => {
  const [email, setEmail] = useState<string>('');
  const [newToken, setToken] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();

  const [companiesOptions, setCompaniesOptions] = useState([] as any);
  const [companiesSelected, setCompanies] = useState([] as any);
  const { companySelected, token } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const getCompanie = async () => {
      const loading = true;
      dispatch(AuthActions.authLoading(loading));
      const data = {
        uuid: companySelected,
      };
      try {
        const response = await api.user.post('/api/v1/login/module', data, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        const { companies } = response.data;
        setToken(response.data.token);
        const tokenNew = response.data.token;
        if (companies) {
          if (companies.length == 1) {
            await setCompanies(companies[0].id);
            const idCompany = companies[0].id;

            if (idCompany == '') {
              toast.error('Escolha um módulo!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              funcErrorCompanie(true);
            } else {
              const companySelected = idCompany;
              const loading = true;
              dispatch(AuthActions.authLoading(loading));
              const data = {
                uuid_company: idCompany,
              };
              try {
                if (
                  companies[0]?.plans == 'CANCELADO' ||
                  companies[0]?.plan == 'CANCELADO'
                ) {
                  const loading = false;
                  dispatch(AuthActions.authLoading(loading));
                  toast.error(
                    'Seu contrato de uso com a GreenPlat está vencido. Caso queira renovar acesse um dos nossos canais de atendimento.',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  const response = await api.user.post(
                    '/api/v1/companies/current',
                    data,
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenNew,
                      },
                    }
                  );
                  const { companies } = response.data;
                  setToken(response.data.token);

                  const dataNew = {
                    token: tokenNew,
                  };
                  window.location.href =
                    'api/v1/logar_by_micro_service/' + response.data.token;
                }
              } catch (error: any) {
                const loading = false;
                dispatch(AuthActions.authLoading(loading));
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  getLogin();
                }

                if (error.response.data.error == 'Provided token is expired.') {
                  getLoginExpired();
                }
              }
            }
          } else {
            const data = companies;
            const options = Object.keys(data)
              .map((key) => ({
                label: `${data[key].trading_name}`,
                value: data[key].id,
              }))
              .filter((option) => option.value !== '');
            setCompaniesOptions(options);
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
          }
        }

        // dispatch(AuthActions.authSelectCompanie(token, { companies }));
      } catch (error: any) {
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
        if (error.response.data.error == 'Provided token is expired.') {
          getLoginExpired();
        }

        if (error.response.data.error == 'No companies bounded to user') {
          toast.error(
            ' Usuário inativo no sistema. Por favor entre em contato com o responsável para reativar seu acesso ao sistema! ',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          const loading = false;
          dispatch(AuthActions.authLoading(loading));
        }
      }
    };

    getCompanie();
  }, [setCompanies]);

  const getCompany = async (code) => {
    if (code.value !== '') {
      try {
        setCompanies(code.value);
        funcErrorCompanie(false);
      } catch (error) {}
    }
  };

  const funcErrorCompanie = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const backLogin = () => {
    history.push('/');
  };

  const getLogin = async () => {
    localStorage.setItem('erro', '2');
    history.push('/');
  };

  const getLoginExpired = async () => {
    localStorage.setItem('erro', '4');
    history.push('/');
  };

  const forgotRequest = async () => {
    if (companiesSelected == '') {
      toast.error('Escolha um módulo!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorCompanie(true);
    } else {
      const companySelected = companiesSelected;
      const loading = true;
      dispatch(AuthActions.authLoading(loading));
      const data = {
        uuid_company: companiesSelected,
      };
      try {
        const response = await api.user.post(
          '/api/v1/companies/current',
          data,
          {
            headers: {
              Authorization: 'Bearer ' + newToken,
            },
          }
        );
        const { companies } = response.data;
        setToken(response.data.token);

        const dataNew = {
          token: newToken,
        };
        window.location.href =
          'api/v1/logar_by_micro_service/' + response.data.token;
      } catch (error: any) {
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
        if (
          error.response.data.error ==
          'Cannot log in as the account is in use on another device!'
        ) {
          getLogin();
        }

        if (error.response.data.error == 'Provided token is expired.') {
          getLoginExpired();
        }
      }
    }
  };

  return (
    // <Container>
    //   <Form>
    //     <LeftBox>
    //       <TextEntre>$$$$$$$$Bem-vindo à Revolução$$$$$$$</TextEntre>

    //       <Logo>
    //         <img src={logo} alt="Plataforma Verde" />
    //       </Logo>

    //       <HRORSSOs>
    //         <TextORSSO>SELECIONE PARA CONTINUAR</TextORSSO>
    //       </HRORSSOs>

    //       <BockInput error={errorEmail}>
    //         <ImageLock>
    //           <img src={company} alt="Plataforma Verde" />
    //         </ImageLock>
    //         <SelectLogin
    //           options={companiesOptions}
    //           placeholder="Selecione um módulo"
    //           onChange={getCompany}
    //           required
    //         />
    //       </BockInput>

    //       <ButtonLogin onClick={() => forgotRequest()} type="button">
    //         CONTINUAR
    //       </ButtonLogin>

    //       <HRORSSOs>
    //         <TextORSSO>OU</TextORSSO>
    //       </HRORSSOs>

    //       <MenuItemLink onClick={() => backLogin()}>
    //         <ButtonBackLogin type="button">
    //           VOLTAR PARA LOGIN/SENHA
    //         </ButtonBackLogin>
    //       </MenuItemLink>

    //       <TextFooter>problemas de acesso? entre em nosso chat</TextFooter>
    //     </LeftBox>

    //     <RightBox>
    //       <LogoRight>
    //         <img src={arte} alt="Plataforma Verde" />
    //       </LogoRight>
    //     </RightBox>
    //   </Form>
    // </Container>
    <Container>
      <FormLogin>
        <LeftBox>
          <section>
            <Logo>
              <img src={logo} alt="Arcos Dourados" />
            </Logo>
            <InputArcos>
              <TextEntre>Escolha o seu gerador para se logar</TextEntre>
              <BockInputArcos error={errorEmail}>
                <SelectLoginArcos
                  options={companiesOptions}
                  placeholder="Selecione um módulo"
                  onChange={getCompany}
                  required
                />
              </BockInputArcos>

              <ButtonArcosContinuar
                onClick={() => forgotRequest()}
                type="button"
              >
                IR
              </ButtonArcosContinuar>
            </InputArcos>
            <ContactGreenPlateArcos />
            <SSMA>
              <img src={logoSSMA} alt="Arcos Dourados" />
            </SSMA>
          </section>
        </LeftBox>

        {/* Same as */}
        <RightBox>
          <ContentTextPage />
        </RightBox>
      </FormLogin>
      <FooterArcos>® 2024 GreenPlat™ Todos os direitos reservados</FooterArcos>
    </Container>
  );
};

export default ChooseModule;
